import React from 'react'
import Image from 'gatsby-image'

const Logo = () => (
  <Image
    fluid={{ src: '/logo.png' }}
    alt=""
    imgStyle={{
      objectFit: 'contain',
    }}
    style={{
      display: 'block',
      margin: '0 auto',
      height: 85,
      width: 95,
    }}
  />
)

export default Logo
